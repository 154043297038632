import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
// Scroll up button
import ScrollUpButton from "react-scroll-up-button";
//Import Icons
import FeatherIcon from "feather-icons-react";
//react loaing spinner
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
// Layout Components
import "./Layout.css";

const Topbar= React.lazy(() => {
  return new Promise((resolve) => setTimeout(resolve, 7000)).then(() =>
    import("./Topbar")
  );
});
// const Topbar = React.lazy(() => import("./Topbar"));

// const NavbarPage = React.lazy(() =>
//   import("../../pages/Saas Onepage/NavbarPage")
// );

const NavbarPage = React.lazy(() => {
  return new Promise((resolve) => setTimeout(resolve, 7000)).then(() =>
    import("../../pages/Saas Onepage/NavbarPage")
  );
});
// const Footer = React.lazy(() => import("./Footer"));

const Footer = React.lazy(() => {
  return new Promise((resolve) => setTimeout(resolve, 7000)).then(() =>
    import("./Footer")
  );
});

// const FooterWithoutMenuLightSocialOnly = React.lazy(() =>
//   import("./FooterWithoutMenuLightSocialOnly")
// );
const FooterWithoutMenuLightSocialOnly = React.lazy(() => {
  return new Promise((resolve) => setTimeout(resolve, 7000)).then(() =>
    import("./FooterWithoutMenuLightSocialOnly")
  );
});



const CustomDot = () => {
  return (
    <i>
      <FeatherIcon icon="arrow-up" className="icons" />
    </i>
  );
};

class Layout extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div id="spinn" className="spinner">
            <ClimbingBoxLoader color="#1C75BC" size={20} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          {this.props.location.pathname === "/index-onepage" ? (
            <NavbarPage />
          ) : (
            <Topbar />
          )}

          {this.props.children}
          {(() => {
            if (
              this.props.location.pathname === "/index-marketing" ||
              this.props.location.pathname === "/index-digital-agency" ||
              this.props.location.pathname === "/index-modern-business" ||
              this.props.location.pathname === "/index-services" ||
              this.props.location.pathname === "/index-job"
            ) {
              return <Footer isLight={true} />;
            } else if (this.props.location.pathname === "/index-portfolio") {
              return (
                <FooterWithoutMenuLightSocialOnly className="border-0 bg-light text-dark" />
              );
            } else if (
              this.props.location.pathname === "/index-personal" ||
              this.props.location.pathname === "/helpcenter-overview" ||
              this.props.location.pathname === "/helpcenter-guides" ||
              this.props.location.pathname === "/helpcenter-support-request" ||
              this.props.location.pathname === "/page-invoice"
            ) {
              return <FooterWithoutMenuLightSocialOnly class="" />;
            } else {
              return <Footer />;
            }
          })()}

          {/* <div className="btn btn-icon btn-soft-primary back-to-top"> */}
          {/* scrollup button */}
          <ScrollUpButton
            ContainerClassName="classForContainer"
            style={{ height: 36, width: 36 }}
            TransitionClassName="classForTransition"
          >
            <CustomDot />
          </ScrollUpButton>
          {/* </div> */}

          {/* theme switcher */}
          {/* <ThemeSwitcher /> */}
        </Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
